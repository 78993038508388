import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import classNames from "classnames";
import { Button } from "@/components/Button";
import { LanguageSelector } from "@/components/LanguageSelector";
import { LanguageCard } from "@/components/LanguageCard";
import sendolaPay from "@/assets/Img/logo-sendola-pay.png";
import sendolaPayWhite from "@/assets/Img/logo-sendola-pay-white.png";

const menuItem =
  "flex w-full gap-2 items-center py-3 hover:text-primary-300 cursor-pointer border-b-[1px] border-b-gray-600 last:border-b-0";
const desktopMenuItem =
  "flex w-full gap-2 items-center py-2 px-4 hover:text-primary-600 cursor-pointer border-b-gray-600 first:pt-4 last:pb-4";
const navItem =
  "text-sm xl:text-base hover:text-gray-300 flex-shrink-0 text-white font-[500]";
const homeNavItem =
  "text-sm xl:text-base hover:text-primary-700 flex-shrink-0 text-black font-[500]";

const AuthUrl = import.meta.env.VITE_AUTH_DOMAIN;
const SendolaBlack = import.meta.env.VITE_SENDOLA_BLACK_URL;

export const Header = () => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const pathname = window.location?.pathname;
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuTouched, setMenuTouched] = useState<boolean>(false);
  const [scrollDirection, setScrollDirection] = useState<"down" | "up">("up");
  const [showLanguageBar, setShowLanguageBar] = useState<boolean>(false);
  const [showOptionsMenu, setShowOptionsMenu] = useState<boolean>(false);
  const [visible, setVisible] = useState(true);
  const [verticalScrolled, setVerticalScrolled] = useState(false);

  const ref = useOutsideClick(() => {
    if (menuOpen) setMenuOpen(false);
  });
  const menuRef = useOutsideClick(() => {
    if (showOptionsMenu) setShowOptionsMenu(false);
  });
  const isGradientRoute = useMemo(() => {
    if (pathname.includes("/banner-bank")) return true;
    if (pathname.includes("/why-sendola")) return true;
    if (pathname.includes("/how-it-works")) return true;
    if (pathname.includes("/for-partners")) return true;
    if (pathname.includes("/sendola-corp")) return true;

    return false;
  }, [pathname]);
  const isHome = useMemo(() => {
    return pathname === "/";
  }, [pathname]);
  const isClaroPay = useMemo(() => {
    return pathname === "/claropay";
  }, [pathname]);

  const navClassNames = useMemo(
    () => ((isHome || isClaroPay) && !verticalScrolled ? homeNavItem : navItem),
    [isHome, isClaroPay, verticalScrolled]
  );

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? "down" : "up";
      setVerticalScrolled(scrollY > 60);

      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
        setVisible(direction === "up");
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener("scroll", updateScrollDirection);

    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection]);

  useEffect(() => {
    if (!currentLanguage) setShowLanguageBar(true);
  }, [currentLanguage]);

  useEffect(() => {
    if (!visible) {
      setMenuOpen(false);
    }
  }, [visible]);

  return (
    <>
      <nav
        className={classNames(
          "z-[100] sticky top-0 transition-all duration-200 ease-in",
          {
            "-translate-y-20 lg:translate-y-0": !visible,
            "translate-y-0": visible,
          }
        )}
      >
        <div
          className={classNames(
            "w-full flex justify-between items-center transition-all",
            {
              "bg-gray-950/80": verticalScrolled && !isGradientRoute,
              "bg-transparent":
                !verticalScrolled && !isGradientRoute && (isHome || isClaroPay),
              "bg-black":
                !verticalScrolled && !isGradientRoute && !isHome && !isClaroPay,
              "h-16 px-4 py-2": true, // Mobile styles
              "lg:px-8 lg:h-20": true, // Desktop styles
              "bg-gradient-to-r from-black from-60% to-black lg:!to-transparent lg:bg-gradient-to-b lg:from-40% lg:-mb-20":
                isGradientRoute,
            }
          )}
        >
          <div className="flex gap-4 md:gap-10 items-center flex-shrink-0">
            <Link to="/">
              {verticalScrolled || (!isHome && !isClaroPay) ? (
                <img
                  className="h-9 sm:h-10 md:h-12 xl:h-14 w-auto object-contain select-none"
                  src={sendolaPayWhite}
                  alt="Sendola's Logo"
                />
              ) : (
                <img
                  className="h-9 sm:h-10 md:h-12 xl:h-14 w-auto object-contain select-none"
                  src={sendolaPay}
                  alt="Sendola's Logo"
                />
              )}
            </Link>

            <div className="flex h-full items-center gap-2 xs:gap-6">
              <Link to="/">
                <h1 className={classNames(navClassNames, "!text-primary-500")}>
                  {t("Header.Personal")}
                </h1>
              </Link>
              <Link to={SendolaBlack}>
                <h1 className={navClassNames}>{t("Header.Business")}</h1>
              </Link>
            </div>
          </div>

          <div
            ref={ref}
            className="flex justify-end flex-shrink-0 lg:hidden w-fit ml-4"
          >
            <LanguageSelector
              blackText={!verticalScrolled && (isHome || isClaroPay)}
              onClick={() => setShowLanguageBar(!showLanguageBar)}
            />
            <Bars3Icon
              className={classNames(
                "w-10 h-10 rounded cursor-pointer hover:bg-gray-900/30 p-2",
                {
                  "bg-gray-900": menuTouched,
                  "text-white": verticalScrolled || (!isHome && !isClaroPay),
                  "text-black": !verticalScrolled && (isHome || isClaroPay),
                }
              )}
              onClick={() => setMenuOpen(!menuOpen)}
              onTouchStart={() => setMenuTouched(true)}
              onTouchEnd={() => setMenuTouched(false)}
            />
          </div>

          <div className="hidden relative h-full lg:flex items-center gap-4">
            <div
              className={
                "px-4 py-1 rounded-lg border-[1px] border-transparent hover:bg-gray-500/10 cursor-pointer select-none"
              }
              onMouseEnter={() => setShowOptionsMenu(true)}
              onMouseLeave={() => setShowOptionsMenu(false)}
            >
              <h1
                ref={menuRef}
                className={classNames({
                  "text-white hover:border-gray-500":
                    verticalScrolled || (!isHome && !isClaroPay),
                  "text-black hover:border-gray-300":
                    !verticalScrolled && (isHome || isClaroPay),
                })}
              >
                {t("Header.SendolaCorp")}
              </h1>

              <div
                className={classNames(
                  "w-fit min-w-52 max-w-80 flex flex-col rounded-lg border-[1px] border-gray-300 bg-white absolute top-[3.4rem] left-0 shadow-lg overflow-hidden transition-all",
                  {
                    "max-h-0 border-none": !showOptionsMenu,
                    "max-h-96": showOptionsMenu,
                  }
                )}
              >
                <Link to="/for-partners" className={desktopMenuItem}>
                  <p className="text-sm">{t("Header.ForPartners")}</p>
                </Link>
                <Link to="/sendola-corp" className={desktopMenuItem}>
                  <p className="text-sm">{t("Header.SendolaCorp")}</p>
                </Link>
                <Link to="/how-it-works" className={desktopMenuItem}>
                  <p className="text-sm">{t("Header.HowItWorks")}</p>
                </Link>
                <Link to="/why-sendola" className={desktopMenuItem}>
                  <p className="text-sm">{t("Header.WhySendola")}</p>
                </Link>
              </div>
            </div>

            <div className="flex gap-2">
              <LanguageSelector
                className="hidden lg:block"
                onClick={() => setShowLanguageBar(!showLanguageBar)}
              />
              <Button
                className="bg-primary-500 !text-white !border-0 !px-8 hover:bg-primary-400"
                size="sm"
                text={t("Header.LoginButton")}
                link={`${AuthUrl}/sign-in`}
              />
              <Button
                className="bg-white !border-0 !px-8 text-primary-500 hover:bg-gray-200"
                variant="secondary"
                size="sm"
                text={t("Header.SignupButton")}
                link={`${AuthUrl}/sign-up`}
              />
            </div>
          </div>
        </div>

        <div
          className={classNames(
            "absolute overflow-hidden transition-all w-max min-w-60 rounded-lg flex-col bg-gradient-to-br from-primary-100 to-60% to-white shadow-lg px-4 top-16 right-0 z-20 box-border",
            {
              "max-h-80": menuOpen,
              "max-h-0": !menuOpen,
            }
          )}
        >
          <Link to="/for-partners" className={menuItem}>
            <p className="text-sm lg:text-base">{t("Header.ForPartners")}</p>
          </Link>
          <Link to="/sendola-corp" className={menuItem}>
            <p className="text-sm lg:text-base">{t("Header.SendolaCorp")}</p>
          </Link>
          <Link to="/how-it-works" className={menuItem}>
            <p className="text-sm lg:text-base">{t("Header.HowItWorks")}</p>
          </Link>
          <Link to="/why-sendola" className={menuItem}>
            <p className="text-sm lg:text-base">{t("Header.WhySendola")}</p>
          </Link>
        </div>
      </nav>

      <LanguageCard
        show={showLanguageBar}
        onClose={() => setShowLanguageBar(false)}
      />
    </>
  );
};
