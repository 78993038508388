export enum SendingMethods {
  ACCOUNT_DEPOSIT = "D2B",
  CASH_PICK_UP = "CPU",
}

export interface PaymentMethod {
  default: boolean;
  paymentOrigin: string;
  paymentMethodFee: number;
  totalCost: number;
}

export interface PaymentDestination {
  id: string;
  destination: string;
  payerSpecificCode: string;
  rail: string;
  isPayerBranchRequired: boolean;
}

export interface DeliveryMethod {
  deliveryMethodId: number;
  deliveryMethodCode: string;
  fee: number;
  fx: number;
  amountToReceive: number;
  paymentMethods: PaymentMethod[];
  paymentDestinations: PaymentDestination[];
}

export interface Quote {
  originCurrency: string;
  destinationCurrency: string;
  amount: number;
  rail: string;
  deliveryMethods: DeliveryMethod[];
  isReceiveAmount?: boolean;
}
