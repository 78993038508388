import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StickyCards } from "@/components/StickyCards";
import { GlobalPaymentsQuoter } from "@/components/GlobalPaymentsQuoter";
import { ArrowRightEndOnRectangleIcon } from "@heroicons/react/24/outline";
import { CreateAccountBanner } from "@/components/CreateAccountBanner/CreateAccountBanner.component";
import WhatsappLogo from "@/assets/Icons/whatsapp.svg?react";
import classNames from "classnames";
import HeadingCover from "@/assets/Img/multiracial-american.jpg";
import CountriesGifEs from "@/assets/Gifs/countries_es.gif";
import CountriesGifEn from "@/assets/Gifs/countries_en.gif";
import SmilingYoungWoman from "@/assets/Img/smiling-young-woman.jpg";
import HappyCouple from "@/assets/Img/happy-couple.jpg";
import admLogo from "@/assets/Img/PartnersLogos/adm.png";
import marisolLogo from "@/assets/Img/PartnersLogos/marisol.png";
import unitellerLogo from "@/assets/Img/PartnersLogos/uniteller.png";
import corpayLogo from "@/assets/Img/PartnersLogos/corpay.png";
import tesoredLogo from "@/assets/Img/PartnersLogos/tesored.png";
import apprizaLogo from "@/assets/Img/PartnersLogos/appriza.png";
import gytLogo from "@/assets/Img/PartnersLogos/gyt.png";
import claroPayEnLogo from "@/assets/Img/PartnersLogos/claro_pay_en.png";
import claroPayEsLogo from "@/assets/Img/PartnersLogos/claro_pay_es.png";
import martoriLogo from "@/assets/Img/PartnersLogos/martori_farms.png";
import interAmericanChamber from "@/assets/Img/PartnersLogos/inter-american-chamber-of-commerce.png";
import northEastChamber from "@/assets/Img/PartnersLogos/northeast-chamber-of-commerce.png";
import anierm from "@/assets/Img/PartnersLogos/anierm.png";
import costaFarmsLogo from "@/assets/Img/PartnersLogos/costa_farms.png";
import usiLogo from "@/assets/Img/PartnersLogos/usi.png";
import omnibusExpressLogo from "@/assets/Img/PartnersLogos/omnibus_express.png";
import omnibusDeMexicoLogo from "@/assets/Img/PartnersLogos/omnibus_de_mexico.png";
import { Helmet } from "react-helmet";
import Marquee from "react-fast-marquee";

const AuthUrl = import.meta.env.VITE_AUTH_DOMAIN;

export default function HomePage() {
  const [t, i18n] = useTranslation("global");
  const lang = i18n.language;

  const [openModal, setOpenModal] = useState(false);

  const countries = useMemo(() => {
    return [
      { icon: "MX", name: t("Countries.MX") },
      { icon: "IN", name: t("Countries.IN") },
      { icon: "GT", name: t("Countries.GT") },
      { icon: "PH", name: t("Countries.PH") },
      { icon: "VN", name: t("Countries.VN") },
      { icon: "SV", name: t("Countries.SV") },
      { icon: "HN", name: t("Countries.HN") },
      { icon: "NI", name: t("Countries.NI") },
      { icon: "CA", name: t("Countries.CA") },
      { icon: "PA", name: t("Countries.PA") },
    ];
  }, [t]);

  useEffect(() => {
    if (!window) return;

    if (window.location?.hash) {
      const id = window.location.hash.replace("#", "");
      const element = document.getElementById(id);

      if (element) element.scrollIntoView();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Sendola | Inicio</title>
      </Helmet>

      <div className="w-full">
        {/* Quoter */}
        <div className="flex flex-col items-center justify-center w-full h-[70rem] lg:h-[56rem] px-6 lg:px-10 -mb-32 -translate-y-32 rounded-t-large pt-44 pb-32 bg-gradient-to-br from-primary-100 to-white">
          <p
            className="text-primary-900 text-2xl sm:text-3xl md:text-4xl text-center inner-span-bolden mb-10 md:mt-6"
            dangerouslySetInnerHTML={{ __html: t("Home.HeadingTitle") }}
          />

          <div className="w-full h-full flex flex-col-reverse lg:flex-row items-center justify-center gap-6 max-w-screen-2xl">
            <div className="relative w-full h-full lg:w-1/2 rounded-3xl overflow-hidden">
              <img
                className="absolute w-full h-full object-cover"
                src={HappyCouple}
                alt="gif countries"
              />
              <div className="absolute w-full h-full object-cover bg-gray-900/50" />
              <div className="absolute w-full h-full flex items-center p-8">
                <h2 className="text-3xl sm:text-5xl 2xl:text-6xl font-semibold inline-block text-white mr-2.5">
                  {t("Home.ScrollCallout")}{" "}
                  <img
                    className="h-28 sm:h-40 2xl:h-48 -my-8 sm:-my-10 md:-my-12 inline object-contain"
                    src={lang === "es" ? CountriesGifEs : CountriesGifEn}
                    alt="gif countries"
                  />
                </h2>
              </div>
            </div>

            <div className="w-full h-full lg:w-1/2">
              <GlobalPaymentsQuoter openModal={() => setOpenModal(true)} />
            </div>
          </div>

          <div className="flex justify-center max-w-screen-md my-10">
            <p className="w-full text-sm text-gray-500 text-center">
              {t("Quoter.Disclaimer3")}
            </p>
          </div>
        </div>

        <div
          id="countries"
          className="flex w-full justify-center px-6 lg:px-10 pt-10 lg:pt-20 -translate-y-20 rounded-t-large bg-white"
        >
          <div className="w-full max-w-screen-2xl flex flex-col items-center justify-center gap-8">
            <p className="text-3xl sm:text-4xl md:text-5xl font-semibold px-4 text-center">
              {t("Home.CountriesWeService")}
            </p>
            <div className="flex flex-col-reverse md:flex-row items-center w-full h-full justify-between gap-8 rounded-large max-w-screen-xl md:px-10 md:mt-6">
              <div className="flex flex-col gap-2 w-full md:w-5/12">
                {countries.map((country, index) => (
                  <div
                    key={`country-${index}`}
                    className="w-full rounded-full flex items-center gap-2 bg-white border-2 border-gray-200 p-2"
                  >
                    <img
                      className="w-6 h-6 rounded-full object-cover"
                      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.icon}.svg`}
                      alt={`${country.name}'s Flag`}
                    />
                    <span className="w-full font-semibold">{country.name}</span>
                  </div>
                ))}
              </div>
              <img
                className="object-cover object-right w-full md:w-7/12 h-auto md:h-[32rem] rounded-large max-w-2xl"
                src={SmilingYoungWoman}
                alt="Smiling young woman"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center p-6 lg:p-10">
          <div
            className={classNames(
              "flex relative justify-center bg-black h-fit overflow-hidden rounded-large mt-6 max-w-screen-2xl",
              {
                "lg:h-fit lg:max-h-[720px] -translate-y-20": true, // Desktop styles
              }
            )}
          >
            <img
              src={HeadingCover}
              alt={t("Home.SendMoney")}
              className="object-cover object-top w-full h-full select-none min-h-[30rem] md:min-h-[28rem] 2xl:min-h-[24rem]"
            />
            <div className="w-full h-full absolute bg-stone-950/60 flex flex-col justify-center items-center px-6">
              <h1
                className="text-3xl lg:text-4xl font-light inner-span-bolden text-white text-center"
                dangerouslySetInnerHTML={{ __html: t("Banners.AmericaGreat") }}
              />
              <p
                className="text-base lg:text-2xl text-white bg-primary-500 font-[500] py-3 md:py-4 px-6 md:px-10 rounded-full max-w-lg lg:max-w-screen-lg text-center mt-10"
                dangerouslySetInnerHTML={{ __html: t("Home.HeadingCallout") }}
              />
            </div>
          </div>
        </div>

        <div
          id="cards"
          className="flex flex-col items-center px-6 lg:px-10 rounded-t-large bg-gradient-to-b from-primary-100 to-white"
        >
          <div className="w-full max-w-screen-2xl">
            <StickyCards />
          </div>

          <div className="w-full max-w-screen-2xl md:mt-10 py-4 sm:py-6">
            <CreateAccountBanner
              title={t("Home.BankAccount.Title")}
              subtitle={t("Home.BankAccount.Subtitle")}
              buttonText={t("Home.BankAccount.Button")}
            />
          </div>
        </div>

        <div
          id="partners"
          className="flex flex-col items-center px-8 sm:px-10 lg:px-20 py-14 lg:py-20 md:pb-40 rounded-t-large bg-gradient-to-br from-primary-100 to-60% to-white"
        >
          <div className="w-full h-full flex flex-col items-center justify-center gap-10 max-w-screen-2xl">
            <h1 className="text-3xl md:text-4xl font-[500] text-center">
              {t("Partners.Title")}
            </h1>

            <div className="w-full flex flex-col justify-center items-center overflow-hidden py-6">
              <Marquee>
                <img
                  className="h-12 2xl:h-16 w-auto object-contain mx-6 lg:mx-10"
                  src={admLogo}
                  alt="ADM Logo"
                />
                <img
                  className="h-14 2xl:h-16 w-auto object-contain mx-6 lg:mx-10"
                  src={marisolLogo}
                  alt="MariSol Logo"
                />
                <img
                  className="h-6 2xl:h-7 w-auto object-contain mx-6 lg:mx-10"
                  src={unitellerLogo}
                  alt="UniTeller Logo"
                />
                <img
                  className="h-8 2xl:h-10 w-auto object-contain mx-6 lg:mx-10"
                  src={corpayLogo}
                  alt="Corpay Logo"
                />
                <img
                  className="h-14 2xl:h-16 w-auto object-contain mx-6 lg:mx-10"
                  src={tesoredLogo}
                  alt="TesoRed Logo"
                />
                <img
                  className="h-9 2xl:h-12 w-auto object-contain mx-6 lg:mx-10"
                  src={apprizaLogo}
                  alt="Appriza Logo"
                />
                <img
                  className="h-12 2xl:h-14 w-auto object-contain mx-6 lg:mx-10"
                  src={gytLogo}
                  alt="G&T Logo"
                />
                {lang === "en" ? (
                  <img
                    className="h-16 2xl:h-20 w-auto object-contain mx-6 lg:mx-10"
                    src={claroPayEnLogo}
                    alt="Claro Pay English Logo"
                  />
                ) : (
                  <img
                    className="h-16 2xl:h-20 w-auto object-contain mx-6 lg:mx-10"
                    src={claroPayEsLogo}
                    alt="Claro Pay Spanish Logo"
                  />
                )}
                <img
                  className="h-20 2xl:h-24 w-auto object-contain mx-6 lg:mx-10"
                  src={interAmericanChamber}
                  alt="Chamber of Commerce Logo"
                />
                <img
                  className="h-20 2xl:h-24 w-auto object-contain mx-6 lg:mx-10"
                  src={northEastChamber}
                  alt="Chamber of Commerce Logo"
                />
                <img
                  className="h-12 md:h-14 2xl:h-16 w-auto object-contain mx-6 lg:mx-10"
                  src={anierm}
                  alt="Anierm Logo"
                />
                <img
                  className="h-12 2xl:h-14 w-auto object-contain mx-6 lg:mx-10"
                  src={costaFarmsLogo}
                  alt="Costa Farms Logo"
                />
                <img
                  className="h-12 2xl:h-14 w-auto object-contain mx-6 lg:mx-10"
                  src={usiLogo}
                  alt="USI Logo"
                />
                <img
                  className="h-12 2xl:h-14 w-auto object-contain mx-6 lg:mx-10"
                  src={martoriLogo}
                  alt="Martori Farms Logo"
                />
                <img
                  className="h-12 2xl:h-14 w-auto object-contain mx-6 lg:mx-10"
                  src={omnibusExpressLogo}
                  alt="Omnibus Express Logo"
                />
                <img
                  className="h-12 2xl:h-14 w-auto object-contain mx-6 lg:mx-10"
                  src={omnibusDeMexicoLogo}
                  alt="Omnibus de México Logo"
                />
              </Marquee>
            </div>

            <div className="w-full flex justify-center mt-10">
              <p className="text-sm text-center text-gray-500">
                {t("Partners.Disclaimer")}
              </p>
            </div>
          </div>
        </div>

        <div
          id="hs-basic-modal"
          className={`hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 fixed flex items-center justify-center top-0 start-0 z-[80] w-full h-full opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none ${
            openModal ? "opacity-100 pointer-events-auto" : "hidden"
          }`}
          role="dialog"
          tabIndex={-1}
          aria-labelledby="hs-basic-modal-label"
          style={{ background: "rgba(0, 0, 0, 0.70)" }}
        >
          <div className="sm:max-w-lg sm:w-full m-3 sm:mx-auto">
            <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:border-neutral-700 dark:shadow-neutral-700/70 p-6">
              <div className="flex justify-between md:justify-center items-center mb-4">
                <h3 id="hs-basic-modal-label" className="font-semibold">
                  {t("Home.Modal.title")}
                </h3>
              </div>
              <div className="mx-auto w-full text-center">
                <button
                  className="flex shadow-xl items-center mx-auto justify-center font-semibold py-2 px-10 bg-primary-500 border-2 border-primary-500 text-white rounded-full border-box hover:opacity-90 w-fit text-base mb-4"
                  onClick={() => window.open(`${AuthUrl}/sign-up`)}
                >
                  <ArrowRightEndOnRectangleIcon className="w-6 h-6 mr-2" />
                  {t("Home.Modal.singUpButton")}
                </button>
                <div className="mb-4 flex">
                  <span className="border-y w-full h-0 slate-200 my-auto" />
                  <span className="mx-2 text-gray-500 text-sm">
                    {t("Home.Modal.or")}
                  </span>
                  <span className="border-y w-full h-0 slate-200 my-auto" />
                </div>
                <div className="mb-4 text-center">
                  <a
                    href={`https://api.whatsapp.com/send?phone=18325251941&text=${t(
                      "Home.Modal.hi"
                    )}`}
                    rel="Sing up"
                    target="_blank"
                    className="flex text-primary-500 text-xs font-semibold text-center justify-center cursor-pointer"
                  >
                    <WhatsappLogo className="w-4 h-4 mr-2" />
                    {t("Home.Modal.whatsappButton")}
                  </a>
                </div>
                <button
                  className="text-gray-400"
                  onClick={() => setOpenModal(false)}
                >
                  {t("Home.Modal.back")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
