export const openApp = (link: string, fallbackLink?: string) => {
  let timeout: number;
  const start = Date.now();
  let isAppOpened = false;

  window.location.href = link;

  if (fallbackLink) {
    timeout = setTimeout(() => {
      if (!isAppOpened) {
        window.open(fallbackLink, "_blank");
      }
    }, 1300) as unknown as number;
  }

  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      isAppOpened = true;
      clearTimeout(timeout);
    }
  };

  const handleWindowFocus = () => {
    const elapsed = Date.now() - start;

    if (elapsed >= 500) {
      isAppOpened = true;
      clearTimeout(timeout);
    }

    cleanup();
  };

  const cleanup = () => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
    window.removeEventListener("focus", handleWindowFocus);
  };

  document.addEventListener("visibilitychange", handleVisibilityChange);
  window.addEventListener("focus", handleWindowFocus);
};

export const getUrl = (
  to: string,
  params?: Record<string, boolean | string>
) => {
  const url = new URL(to);
  const query = new URLSearchParams();

  if (params) {
    for (const [key, value] of Object.entries(params)) {
      if (typeof value === "boolean") {
        query.append(key, "1");
      } else {
        query.append(key, value);
      }
    }
  }

  url.search = query.toString();

  return url;
};

export const redirectTo = (
  to: string,
  params?: Record<string, boolean | string>
) => {
  const url = getUrl(to, params);

  window.location.href = url.toString();
};
