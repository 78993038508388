import { Helmet } from "react-helmet";
import ClaroPayBannerEs from "@/assets/Img/ClaroPay/banner-es.png";
import ClaroPayBannerEn from "@/assets/Img/ClaroPay/banner-en.png";
import ClaroPayLargeLogo from "@/assets/Img/ClaroPay/claropay-large.svg?react";
import ClaroPaySmallLogo from "@/assets/Img/ClaroPay/claropay-small.png";
import InbursaLogo from "@/assets/Img/ClaroPay/inbursa-logo.svg?react";
import PhoneRingingIcon from "@/assets/Icons/phone-ringing.svg?react";
import ReceiptCheckIcon from "@/assets/Icons/receipt-check.svg?react";
import CoinSendIcon from "@/assets/Icons/coin-send.svg?react";
import WithdrawIcon from "@/assets/Icons/withdrawal.svg?react";
import ShoppingCartCheckIcon from "@/assets/Icons/shopping-cart-check.svg?react";
import GirlPhone from "@/assets/Img/ClaroPay/girl-phone.png";
import sendolaPay from "@/assets/Img/logo-sendola-pay.png";
import UsaToMX from "@/assets/Img/ClaroPay/claropay-usa-mx.png";
import FoldClaroBG from "@/assets/Img/ClaroPay/fold-claro-pay-features-bg.png";
import FoldSendolaBG from "@/assets/Img/ClaroPay/fold-claro-sendola-bg.png";

import { useTranslation } from "react-i18next";
import {
  CLAROPAY_ANDROID_DEEPLINK,
  CLAROPAY_APPSTORE,
  CLAROPAY_IOS_DEEPLINK,
  CLAROPAY_PLAYSTORE,
  CLAROPAY_WEB_DEEPLINK,
} from "@/constants/ClaroPay";
import { isAndroid, isIOS } from "react-device-detect";
import { openApp } from "@/utilities/Redirect";
import { Button } from "@/components/Button";

const AuthUrl = import.meta.env.VITE_AUTH_DOMAIN;

export default function ClaroPayPage() {
  const [t, i18n] = useTranslation("global");
  const lang = i18n.language;

  const openClaroPayApp = () => {
    const androidDeepLink = CLAROPAY_ANDROID_DEEPLINK;
    const iosDeepLink = CLAROPAY_IOS_DEEPLINK;
    let finalDeepLink = CLAROPAY_WEB_DEEPLINK;
    let fallbackDeepLink = CLAROPAY_WEB_DEEPLINK;
    if (isAndroid) {
      finalDeepLink = androidDeepLink;
      fallbackDeepLink = CLAROPAY_PLAYSTORE;
    } else if (isIOS) {
      finalDeepLink = iosDeepLink;
      fallbackDeepLink = CLAROPAY_APPSTORE;
    }
    openApp(finalDeepLink, fallbackDeepLink);
  };

  return (
    <>
      <Helmet>
        <title>Sendola | ClaroPay</title>
      </Helmet>
      <div className="w-full flex flex-col items-center justify-center relative">
        <div className="w-full md:!min-h-[550px] 2xl:!min-h-[750px] flex flex-col items-center bg-white relative overflow-y-visible z-30">
          <div className="w-full bg-[#f4f4f4] py-12 px-14 flex flex-col items-center md:items-start justify-center gap-5">
            <div className="w-full md:w-1/2 flex items-center justify-center gap-5">
              <img
                className="h-16 md:h-20 2xl:h-32 w-auto object-contain select-none"
                src={sendolaPay}
                alt="Sendola's Logo"
              />
              <div className="w-0.5 h-10 bg-gray-400"></div>
              <img
                className="h-16 md:h-20 2xl:h-32 w-auto object-contain select-none"
                src={ClaroPaySmallLogo}
                alt="ClaroPay Logo"
              />
            </div>
            <p className="w-full md:w-1/2 text-center text-2xl lg:text-3xl 2xl:text-4xl">
              {t("ClaroPay.posibilitiesWorld")}
            </p>
          </div>
          <div className="w-full flex justify-between items-start p-10 pt-14 md:pt-0 md:pb-0">
            <div className="w-full md:w-1/2 flex flex-col items-center justify-center md:pt-14">
              <img
                src={UsaToMX}
                alt="ClaroPay"
                className="w-full max-w-80 2xl:max-w-96 object-center"
              />
            </div>
            <img
              src={GirlPhone}
              alt="Girl with phone"
              className="hidden md:block w-6/12 lg:w-5/12 xl:w-[500px] 2xl:w-[700px] -mb-96 -mt-44 2xl:-mb-[700px]"
            />
          </div>
        </div>

        <div
          className="w-full flex flex-col items-center gap-3 px-5 md:px-10 py-5 pb-10 md:py-20 md:-mt-10 bg-auto md:bg-[size:100%_100%] bg-bottom bg-no-repeat md:z-30 relative"
          style={{
            backgroundImage: `url(${FoldSendolaBG})`,
          }}
        >
          <div className="w-full">
            <div className="w-full max-w-[1040px] 2xl:max-w-[1400px] mx-auto">
              <p className="w-full md:max-w-[300px] lg:max-w-[400px] 2xl:max-w-max text-center md:text-left text-2xl lg:text-3xl xl:text-4xl 2xl:text-4xl font-medium text-black mx-auto md:mx-0 mb-5 md:-mt-10 lg:-mt-0">
                {t("ClaroPay.sendMoney")}&nbsp;
                <span className="text-center text-2xl lg:text-3xl xl:text-4xl 2xl:text-4xl font-light text-black">
                  {t("ClaroPay.toMex")}
                </span>
              </p>
              <div className="flex justify-center md:justify-start gap-5">
                <img
                  src={sendolaPay}
                  alt="Sendola's Logo"
                  className="w-auto h-16 xl:h-24 object-contain select-none"
                />
                <img
                  src={ClaroPaySmallLogo}
                  alt="ClaroPay Logo"
                  className="w-auto h-16 xl:h-24 object-contain select-none"
                />
              </div>
            </div>

            <div className="w-full max-w-[1400px] mx-auto relative">
              <img
                src={lang === "es" ? ClaroPayBannerEs : ClaroPayBannerEn}
                alt="Claro Pay Banner - Envía dinero a México con Sendola Pay y Claro Pay"
                className="w-full max-w-[1400px] self-center mx-auto md:-mt-11 object-center"
              />
              <p className="w-full max-w-[315px] text-center text-xs font-medium text-black mx-auto md:absolute -bottom-5 right-[17%]">
                {t("ClaroPay.quoteDisclaimer")}
              </p>
            </div>
            <div className="flex justify-center md:justify-end w-full max-w-2xl mx-auto pt-10 z-50">
              <Button
                className="bg-primary-500 !border-0 !px-9 !py-5 text-white hover:bg-primary-400"
                variant="secondary"
                size="xl"
                text={t("Header.SignupButton")}
                link={`${AuthUrl}/sign-up`}
              />
            </div>
          </div>
        </div>

        <div
          className="w-full flex flex-col items-center gap-3 px-5 md:px-10 py-5 pb-10 md:py-20 md:-mt-28 bg-auto md:bg-[size:100%_100%] bg-bottom bg-no-repeat md:!min-h-[850px] md:z-30"
          style={{
            backgroundImage: `url(${FoldClaroBG})`,
          }}
        >
          <div className="w-full max-w-[1040px] z-50">
            <p className="w-full md:w-72 lg:w-96 text-center md:text-left text-2xl lg:text-3xl xl:text-3xl 2xl:text-4xl font-medium text-white md:-mt-[50px] md:mb-20 mb-5">
              {t("ClaroPay.discoverAll")}&nbsp;
              <span className="text-center text-2xl lg:text-3xl xl:text-3xl 2xl:text-4xl font-light text-white">
                {t("ClaroPay.youCanDo")}
              </span>
            </p>
            <div className="w-full max-w-2xl flex flex-col mx-auto gap-10 rounded-[42px] drop-shadow-lg bg-white p-5 md:p-10 [&_b]:font-medium">
              <div className="flex items-start p-0.75 gap-5">
                <div className="w-auto">
                  <div className="w-[45px] h-[45px] flex items-center justify-center rounded-full bg-[#d92f27]">
                    <PhoneRingingIcon
                      width={30}
                      height={30}
                      className="text-white"
                    />
                  </div>
                </div>
                <p
                  className="text-left text-lg md:text-xl"
                  dangerouslySetInnerHTML={{
                    __html: t("ClaroPay.features.rechargeAccount"),
                  }}
                />
              </div>
              <div className="flex items-start p-0.75 gap-5">
                <div className="w-auto">
                  <div className="w-[45px] h-[45px] flex items-center justify-center rounded-full bg-[#d92f27]">
                    <ReceiptCheckIcon
                      width={30}
                      height={30}
                      className="text-white"
                    />
                  </div>
                </div>
                <p
                  className="text-left text-lg md:text-xl"
                  dangerouslySetInnerHTML={{
                    __html: t("ClaroPay.features.payYourServices"),
                  }}
                />
              </div>
              <div className="flex items-start p-0.75 gap-5">
                <div className="w-auto">
                  <div className="w-[45px] h-[45px] flex items-center justify-center rounded-full bg-[#d92f27]">
                    <CoinSendIcon
                      width={32}
                      height={32}
                      className="text-white"
                    />
                  </div>
                </div>
                <p
                  className="text-left text-lg md:text-xl"
                  dangerouslySetInnerHTML={{
                    __html: t("ClaroPay.features.sendAndReceiveMoney"),
                  }}
                />
              </div>
              <div className="flex items-start p-0.75 gap-5">
                <div className="w-auto">
                  <div className="w-[45px] h-[45px] flex items-center justify-center rounded-full bg-[#d92f27]">
                    <WithdrawIcon
                      width={32}
                      height={32}
                      className="text-white"
                    />
                  </div>
                </div>
                <p
                  className="text-left text-lg md:text-xl"
                  dangerouslySetInnerHTML={{
                    __html: t("ClaroPay.features.withdrawMoney"),
                  }}
                />
              </div>
              <div className="flex items-start p-0.75 gap-5">
                <div className="w-auto">
                  <div className="w-[45px] h-[45px] flex items-center justify-center rounded-full bg-[#d92f27]">
                    <ShoppingCartCheckIcon
                      width={30}
                      height={30}
                      className="text-white"
                    />
                  </div>
                </div>
                <p
                  className="text-left text-lg md:text-xl"
                  dangerouslySetInnerHTML={{
                    __html: t("ClaroPay.features.buyInStores"),
                  }}
                />
              </div>
              <div className="w-full flex items-center justify-center">
                <button
                  type="button"
                  className="py-4 px-12 w-fit text-white text-lg font-medium bg-[#d92f27] rounded-full shadow-md hover:opacity-90 transition duration-300 ease-in-out absolute -bottom-5 md:right-5"
                  onClick={openClaroPayApp}
                >
                  {t("ClaroPay.openAccount")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container p-10 flex items-center justify-center gap-5 flex-wrap">
          <p className="text-2xl">{t("ClaroPay.requestYourAccount")}</p>
          <ClaroPayLargeLogo width={150} />
          <InbursaLogo width={150} />
        </div>
      </div>
    </>
  );
}
