import { useTranslation } from "react-i18next";
import PlaidLogo from "@/assets/Icons/plaid-logo.svg?react";
import ZelleLogo from "@/assets/Icons/zelle.svg?react";
import VenmoLogo from "@/assets/Icons/venmo.svg?react";
import PayPalLogo from "@/assets/Icons/paypal.svg?react";
import ACHLogo from "@/assets/Icons/ach.svg?react";
import PaymentMethodEn from "@/assets/Img/payment-method-mock-en.png";
import PaymentMethodEs from "@/assets/Img/payment-method-mock-es.png";
import BeneficiaryEn from "@/assets/Img/beneficiary-mock-en.png";
import BeneficiaryEs from "@/assets/Img/beneficiary-mock-es.png";
import PaymentEnLogo from "@/assets/Img/payment-mock-en.png";
import PaymentEsLogo from "@/assets/Img/payment-mock-es.png";
import BannerBankLogo from "@/assets/Img/PartnersLogos/banner-bank-logo-white.png";
import { Card } from "@/components/Card";

import "./styles.module.css";

export const StickyCards = () => {
  const [t, i18n] = useTranslation("global");
  const lang = i18n.language;

  return (
    <div className="flex flex-col gap-8 w-full pt-10 lg:pt-20 lg:flex-row max-w-screen-sm lg:max-w-none">
      <div className="sticky top-20 w-full pb-64 md:pb-96 md:h-full lg:pb-10 lg:top-[100px] max-w-screen-lg">
        <h2
          className="font-semibold text-2xl md:text-3xl lg:text-6xl 2xl:text-7xl lg:mt-10"
          dangerouslySetInnerHTML={{ __html: t("Home.Section2.Title") }}
        />
        <p
          className="text-lg lg:text-2xl 2xl:text-3xl lg:my-4"
          dangerouslySetInnerHTML={{ __html: t("Home.Section2.Subtitle") }}
        />
      </div>
      <div className="flex flex-col pb-20 items-end -mt-64 md:-mt-96 lg:mt-0 w-full">
        <Card
          className="!bg-primary-500 text-white min-w-80 max-w-[40rem] p-10 md:py-12 !rounded-large h-[28rem] sm:h-[32rem] md:h-[38rem] w-full overflow-hidden sticky top-[230px] sm:top-[200px] lg:top-[140px]"
          noPadding
          rest
        >
          <div className="flex flex-col h-full">
            <p className="text-2xl sm:text-3xl leading-tight font-[500] md:text-4xl">
              {t("Cards.Card1.Title")}
            </p>
            <div className="w-full leading-tight">
              <PlaidLogo className="my-2 max-w-24 lg:h-9 text-white" />
              <p
                className="text-base md:text-lg max-w-80"
                dangerouslySetInnerHTML={{
                  __html: t("Cards.Card1.Paragraph"),
                }}
              />
            </div>
            <div className="h-full w-full flex justify-center translate-y-4">
              {lang === "en" ? (
                <img
                  className="h-[30rem] md:h-[38rem] w-auto -my-6 object-contain object-top"
                  src={PaymentMethodEn}
                />
              ) : (
                <img
                  className="h-[30rem] md:h-[38rem] w-auto -my-6 object-contain object-top"
                  src={PaymentMethodEs}
                />
              )}
            </div>
          </div>
        </Card>

        <Card
          className="!bg-primary-dark text-white min-w-80 max-w-[40rem] p-10 md:py-12 !rounded-large h-[28rem] sm:h-[32rem] md:h-[38rem] w-full overflow-hidden sticky top-[230px] sm:top-[200px] lg:top-[140px] translate-y-[40px] md:translate-y-[50px]"
          noPadding
          rest
        >
          <div className="flex flex-col gap-2 h-full">
            <p className="text-2xl sm:text-3xl leading-tight font-[500] md:text-4xl">
              {t("Cards.Card2.Title")}
            </p>
            <div className="text-[10px] md:text-base w-full">
              <p className="text-sm sm:text-base md:text-lg leading-tight mt-2">
                {t("Cards.Card2.Paragraph")}
              </p>

              <div className="flex flex-col sm:flex-row">
                <div className="flex flex-col gap-6 sm:gap-16">
                  <div className="sm:pt-10">
                    <ul className="border-[1px] border-white rounded-xl p-2 list-disc pl-8 mt-2">
                      <li className="text-xs md:text-sm">
                        {t("Cards.Card2.Bullet1")}
                      </li>
                    </ul>
                    <ul className="border-[1px] border-white rounded-xl p-2 list-disc pl-8 mt-2">
                      <li className="text-xs md:text-sm">
                        {t("Cards.Card2.Bullet2")}
                      </li>
                    </ul>
                  </div>

                  <img
                    className="h-8 sm:h-12 xl:h-14 w-auto object-left object-contain max-sm:-my-2 max-sm:object-center"
                    src={BannerBankLogo}
                  />
                </div>

                <div className="h-full w-full flex flex-shrink-0 mt-8 sm:mt-4 sm:-mr-28 sm:-ml-32 justify-center translate-y-4 pt-4">
                  {lang === "en" ? (
                    <img
                      className="h-[24rem] sm:h-[30rem] md:h-[26rem] xl:h-[38rem] object-contain object-top max-xs:object-right w-auto -my-14 sm:-my-10"
                      src={BeneficiaryEn}
                    />
                  ) : (
                    <img
                      className="h-[24rem] sm:h-[30rem] md:h-[26rem] xl:h-[38rem] object-contain object-top max-xs:object-right w-auto -my-14 sm:-my-10"
                      src={BeneficiaryEs}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>

        <Card
          className="!bg-secondary text-white min-w-80 max-w-[40rem] p-10 md:py-12 !rounded-large h-[28rem] sm:h-[32rem] md:h-[38rem] w-full overflow-hidden sticky top-[230px] sm:top-[200px] lg:top-[140px] translate-y-[80px] md:translate-y-[100px]"
          noPadding
          rest
        >
          <div className="flex flex-col h-full">
            <p className="text-2xl sm:text-3xl leading-tight font-[500] md:text-4xl">
              {t("Cards.Card3.Title")}
            </p>

            <div className="text-[10px] md:text-base w-full">
              <p
                className="text-base md:text-lg leading-tight mt-2"
                dangerouslySetInnerHTML={{
                  __html: t("Cards.Card3.Paragraph"),
                }}
              />

              <div className="flex flex-col sm:flex-row sm:mt-4">
                <div className="grid grid-cols-2 w-full sm:flex sm:flex-col gap-4 sm:gap-10 md:gap-14 sm:w-1/3 mt-8 sm:mt-20">
                  <ZelleLogo className="h-8 w-full object-contain" />
                  <VenmoLogo className="h-6 w-full object-contain" />
                  <PayPalLogo className="h-8 w-full object-contain" />
                  <ACHLogo className="h-7 w-full object-contain" />
                </div>

                <div className="h-full flex-1 flex flex-shrink-0 mt-8 sm:mt-4 sm:-mr-28 sm:-ml-32 justify-center translate-y-4 pt-4">
                  {lang === "en" ? (
                    <img
                      className="h-[24rem] sm:h-[32rem] md:h-[30rem] xl:h-[38rem] w-auto -my-14 sm:-my-10 object-contain object-top"
                      src={PaymentEnLogo}
                    />
                  ) : (
                    <img
                      className="h-[24rem] sm:h-[32rem] md:h-[30rem] xl:h-[38rem] w-auto -my-14 sm:-my-10 object-contain object-top"
                      src={PaymentEsLogo}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
